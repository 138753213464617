.serviceCard {
    width: 250px;
    transition: 0.8s all ease-in-out;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    cursor: pointer;
}
.serviceContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    justify-content: space-evenly;
    gap: 3rem;
    align-items: center;
    padding: 2rem;
}
.sImg {
    padding-left: 15px;
}
.serviceCard:hover {
    transform: scale(1.05);
}
@media only screen and (max-width: 900px) {
    .serviceContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 1rem auto;
        gap: 1rem;
    }
    .serviceCard {
        width: 80%;
        transition: 0.8s all ease-in-out;
    }
    .cardTop {
        padding-bottom: 1.5rem;
    }
}