.menuIcon, .closeIcon{
  visibility: hidden;
}
header{
  width: 100%;
  max-height: 80px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  border-bottom: 1px solid black;
  box-shadow: 0px 2px 32px 1px rgba(0,0,0,0.76);

}
.logo{
  margin-left: 4rem;
}
.navbarList{
  display: flex;
  flex-direction: inline-flex;
  gap: 1rem;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin-top: 1.7rem;
  margin-left: 4rem;
}
a{
  text-decoration: none;
  color: #000000;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 1.4rem;
  transition: color 0.4s ease-in-out;
}
a:hover{
  color: #c5b20b;
  text-decoration: none;
}
.fono {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fono span {
  padding-left: 10px;
}
.fono img {
  width: 30px;
  height: 30px;
  margin-left: 15px;
}
@media only screen and (max-width: 900px) {
  .fono {
    visibility: hidden;
  }
  .menuIcon {
    visibility: visible;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    position: relative;
    top: 0.8rem;
    right: 4rem;
    font-size: 2rem;
  }
  .closeIcon {
    visibility: visible;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    position: fixed;
    top: 2rem;
    right: 4rem;
    font-size: 3rem;
  }
  .responsive{
    transform: translateY(0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s ease-in-out;
    transform: translateY(-100vh);
    background-color: rgb(135, 121, 13);
  }
  .navbarList{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  a{
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 1.4rem;
    transition: all 1s ease-out;
  }
  .closeIcon, nav{
    z-index: 2;
  }
  header{
    width: 100%;
    height: 5rem;
    display: flex;
  }
  .logo{
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    margin-top: 0.6rem;
    margin-left: 1rem;
  }
  
}