.container-f {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}
label {
    display: flex;
    justify-content: flex-start;
    padding-left: 12px;
    margin-top: 12px;
    margin-bottom: 10px;
    font-size: 1.3rem;
    font-weight: 600;
}
.form-control {
    border-radius: 5px;
    border: 2px solid black;
    padding: 14px;
}
.form-control::placeholder {
    font-size: 1rem;
    font-weight: 600;
    color: rgb(143, 143, 143);
}
.btnForm {
    margin-top: 1rem;
    padding: 6px 12px;
    font-size: 1.4rem;
    border-radius: 12px;
    color: white;
    background-color: gray;
    border: transparent;
    cursor: pointer;
    transition: 0.4s all ease-in-out;
}
.btnForm:hover {
    transform: scale(1.1);
    color: black;
    background-color: #a5b94b;
}