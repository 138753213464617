.cImage {
    width: 100%;
    height: 35vw; /* Use relative unit */
    vertical-align: middle;
    object-fit: fill;
} 

.cContainer {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Change flex direction for smaller screens */
}

.ctitle {
    text-transform: uppercase;
}

.slider-container {
    position: relative;
}

.slide-text {
    position: absolute;
    bottom: 0;
    color: white;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.slide-text h2 {
    padding: 1rem 10%; /* Use relative unit */
    line-height: 2rem;
    letter-spacing: 3px;
}

@media only screen and (max-width: 900px) {

    .cImage {
        height: 60vw; /* Adjust height for smaller screens */
    }

    .slide-text {
        bottom: 0;
        top: 0;
        padding-top: 20%;
        padding-bottom: 10%;
    }

    .slide-text h2 {
        padding: 0.1rem 5%; /* Adjust padding for smaller screens */
        font-size: 1.5rem; /* Reduce font size for smaller screens */
    }
}
