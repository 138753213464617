.floatBtn {
    position: fixed;
    width: 70px;
    height: 70px;
    right: 40px;
    bottom: 40px;
    display: scroll;
    z-index: 2;
}
.wIcon {
    width: 100px;
    height: 100px;
}
@media only screen and (max-width: 900px) {
    .floatBtn {
        width: 100%;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        position: relative;
        
    }
    .wIcon {
        width: 70px;
        height: 70px;
    }
}