* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600&family=Roboto:wght@300;400;500;700&display=swap");

  body {
    margin: 0;
    font-family: "Noto Sans", "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }